import React, { useContext } from 'react';
import { Box, Typography, Link, Alert } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Helmet } from 'react-helmet';

import UserContext from "./../contexts/UserContext";
import TableEnhanced from "./../components/TableEnhanced";

const columns = [
    {
      field: 'Expr',
      numeric: false,
      disablePadding: false,
      label: 'Expression',
      filter: true,
    },
    {
      field: 'Error',
      type: 'icon',
      numeric: false,
      disablePadding: false,
      label: 'Status',
      filter: false,
      editDialogHide: true,
    },
    {
      field: 'Enabled',
      type: 'switch',
      disablePadding: false,
      label: 'Enabled',
      align: 'right',
    },
  ];

export default function Alerts() {
    const { user } = useContext(UserContext);

    return (
        <div>
            <Helmet>
                <title>Alerts - ontons.com</title>
                <meta name="description" content="Free crypto alerts. Cryptocurrency alerts, alarms, notifications, reminders. Bitcoin telegram price alerts." />
            </Helmet>
            <Typography component="div" variant="h4">Crypto alerts</Typography>  
            <Typography component="div" variant="body1" sx={{ mt: 1, mb: 1}}>
            Create cryptocurrency price and indicator alerts. Receive notifications through Telegram message. 
            Price data is taken from <Link href={process.env.REACT_APP_BINANCELINK} target="_blank">Binance</Link>. 
            </Typography> 

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography component="div">Examples</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography component="div">
                  
                        'BTC&gt;80000.4' - send alert <b>once</b> when Bitcoin-USDT price rises above 80000.4<br/>
                        'ETHBTC&lt;0.067' - send alert <b>once</b> when Etherium-Bitcoin price drops below 0.067<br/>
                        'BTC%2' - send alert <b>each time</b> when Bitcon price changes by 2% in 15 minute timeframe<br/>
                        'BTC(RSI)&lt;22' - send alert <b>each time</b> when Bitcon 15 minute RSI drops below 22 <br/>
                        '*%5' - send alert <b>each time</b> any coin price changes by 5% in 5 minute timeframe <br/>
                        <br/>
                       
                        Currently supported indicators: RSI 15m
            
                </Typography>
                </AccordionDetails>
            </Accordion>
            {user.LoggedIn &&
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                >
                <Typography component="div">Telegram link</Typography>
                </AccordionSummary>
               
                <AccordionDetails>
                <Typography component="div">
                {user.LoggedIn && !user.TgLinked &&
                    <Alert severity="warning" variant="outlined" sx={{ mt: 2 }}>
                    To be able to receive Telegram messages please send this code to our bot <Link href={`https://t.me/${process.env.REACT_APP_TELEGRAMBOT}`} target="_blank">@{process.env.REACT_APP_TELEGRAMBOT}</Link>: {user.TgLink}
                    </Alert>
                } 
                {user.LoggedIn && user.TgLinked &&
                    <Alert severity="info" variant="outlined" sx={{ mt: 2 }}>
                    Telegram linked. To link a different telegram account please send this code to our bot <Link href={`https://t.me/${process.env.REACT_APP_TELEGRAMBOT}`} target="_blank">@{process.env.REACT_APP_TELEGRAMBOT}</Link>: {user.TgLink}
                    </Alert>
                } 
                </Typography>
                </AccordionDetails>
            </Accordion>
            }

        {!user.LoggedIn && !user.loading && 
        <div>
            <Box sx={{ mt: 2 }}>Please sign in to create alerts or use our Telegram bot <Link href={`https://t.me/${process.env.REACT_APP_TELEGRAMBOT}`} target="_blank">@{process.env.REACT_APP_TELEGRAMBOT}</Link>.</Box>
            </div>
        }
        {user.LoggedIn && 
            <div>
                <TableEnhanced 
                    title="" 
                    entityName="alert" 
                    dense 
                    url="/s/alert"
                    defaultSort="Expr" 
                    rowsPerPage={10} 
                    columns={columns} 
                    />
            </div>
        }
        </div>
    )
}