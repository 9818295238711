import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, Box, Typography, Link } from '@mui/material';

import { url, exchUrl, decimals } from './../functions.js'

class SimpleList extends Component {
    constructor(props) {
        super(props);
        this.state = {rates: []};
    }

    fetchData() {
        fetch(url('/s/rates'))
        .then(res => res.json())
        .then(json => {
            this.setState({ rates: json });
        });
        
    }

    componentDidMount() {
        this.fetchData();
        setInterval(() => {
            this.fetchData();
        }, 3000);
    }

    render() {
        return (
            <Card sx={{ display: 'flex', flexDirection: 'column', pb: 1 }}>
                <Box sx={{ p: 1 }}>
                    <Typography sx={{ fontWeight: 400 }} component="div">{this.props.title}</Typography>
                </Box>
                {!this.props.rows && <Typography component="div" sx={{ px: 2}}>Preparing data...</Typography>}
                {this.props.rows?.map((row, i) => (
                    <Grid container alignItems="stretch" sx={{ px: 2}} key={row[this.props.id]}>
                        {this.props.cols.map((column) => (
                            <Grid item xs={column.size} key={column.field}>
                                <Typography component="div" sx={column.sx}>
                                {column.type == 'exchUrl' && 
                                    <Typography component="div" sx={{ fontWeight: 'bold', fontSize: 15 }}>
                                        <Link target="_blank" href={exchUrl("Binance", row.BaseAsset, row.QuoteAsset)}>{row.BaseAsset}</Link> 
                                    </Typography>
                                }    
                                {column.type == 'plusPercent' && 
                                    <Typography component="div" sx={{ textAlign:'right', fontSize: 15, color: 'green'}}>
                                        +{decimals(row[column.field], 2)}%
                                    </Typography>
                                }    
                                {column.type == 'minusPercent' && 
                                    <Typography component="div" sx={{ textAlign:'right', fontSize: 15, color: 'red'}}>
                                        {decimals(row[column.field], 2)}%
                                    </Typography>
                                }  
                                {!column.type && 
                                    <Typography component="div" sx={{ textAlign:column.textAlign, fontSize: 15, color: column.color}}>
                                        {(!column.func && row[column.field]) || (typeof column.func === 'function' && column.func(row[column.field]))}
                                    </Typography>
                                }
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                ))}
                
            </Card>
        );
    }
}

SimpleList.propTypes = {
    title: PropTypes.string,
    id: PropTypes.string,
    cols: PropTypes.array,
    rows: PropTypes.array
}

export default SimpleList;