import React, { useEffect, useState } from 'react'
import { Grid, Box, Chip, Card } from '@mui/material';
import { List, ListItem, Divider, ListItemText, ListItemAvatar, Avatar, Typography } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import { Helmet } from 'react-helmet';
import moment from 'moment';

import Loading from './../components/Loading';
import { url } from './../functions.js'

export default function News() {
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    var jo = document.createElement("script");
    jo.type = "text/javascript";
    jo.id= "FJ-Widgets";
    var r = Math.floor(Math.random() * (9999 - 0 + 1) + 0);
    jo.src = "https://www.financialjuice.com/widgets/widgets.js?r=" + r + "";
    jo.onload = function(){ 
      var options = {};
      options.container = "financialjuice";
      options.mode = "table";
      options.width= "575px";
      options.height= "600px";
      options.backColor= "#F5F5F5";
      options.fontColor= "#000000";
      options.widgetType= "ECOCAL";
      new window.FJWidgets.createWidget(options);
    } 
    document.getElementsByTagName("head")[0].appendChild(jo);

    fetch(url('/s/events'))
      .then(res => res.json())
      .then(json => {
        setData(json.Data);
        setIsLoading(false);
      });
  }, []);
  
 
  return (
    <div>
      <Helmet>
        <title>Events - ontons.com</title>
        <meta name="description" content="Cryptocurrency events calendar" />
      </Helmet>

      <Loading show={isLoading} />

      <Grid container spacing={1} alignItems="stretch">
          <Grid item xs={12} md={6} >
            <h4>Crypto events</h4>
            
            {Object.keys(data).map((date) => (
              <Box key={date}>
                <Box sx={{ fontWeight: 500, my: 1 }}>{moment(date, "x").format('MMMM DD')}</Box>

                <Card>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {data[date]?.map((event, i) => (
                  <Box key={event.ID}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt={event.CoinName} src={event.CoinImg} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={event.CoinName + " (" + event.CoinSymbol +")" + " - " + event.Title}
                      secondaryTypographyProps={{component:"div"}}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="div"
                            variant="body2"
                            color="text.primary"
                          >
                          
                          </Typography>
                          {event.Content} 
                          <Box sx={{ fontWeight: 'light', mt: 2 }}>
                            <Chip 
                              size="small" 
                              
                              color="primary" 
                              label="Source"
                              component="a" 
                              target="_blank"
                              icon={<LinkIcon />}
                              href={event.Source}
                              clickable
                            />
                          
                          </Box>
                          
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  { i + 1 < data[date].length && <Divider variant="inset" component="li" />}
                  </Box>
                ))}
                </List>
                </Card>
                
              </Box>
              ))}
            

            
          </Grid>

          <Grid item xs={12} md={6}>
            <h4>Economic events</h4>
            <div id="financialjuice"></div>
          </Grid>
      
        
      </Grid>
      


    </div>
  )
}