import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'

const ConfirmDialog = props => {
  const handleClose = () => {
    props.setOptions(s => ({...s, ...{open: false}}));
  }
  const handleYes = () => {
    props.options.yesHandler()
    props.setOptions(s => ({...s, ...{open: false}}));
  }

  return (
    <Dialog
        open={props.options.open || false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.options.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.options.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>No</Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
  )
}

ConfirmDialog.propTypes = {
    options: PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
        open: PropTypes.bool,
        yesHandler: PropTypes.func,
    }),
    setOptions: PropTypes.func
}

export default ConfirmDialog