import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Stack, Button } from '@mui/material'
import { TableSortLabel, Toolbar, Typography, Paper, Checkbox, Tooltip  } from '@mui/material'
import { visuallyHidden } from '@mui/utils';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ErrorIcon from '@mui/icons-material/Error';
import CheckIcon from '@mui/icons-material/Check';
import { red, green } from '@mui/material/colors';

import EditDialog from './EditDialog';
import ConfirmDialog from './ConfirmDialog';
import Loading from './Loading';
import { url } from './../functions.js'
import UserContext from "./../contexts/UserContext";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
    return -1;
  }
  if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
    return 1;
  }
  return 0;
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  if (comparator) {
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
          return order;
        }
        return a[1] - b[1];
      });
  }
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all',
            }}
          />
        </TableCell>
        {props.columns.map((column) => (
          <TableCell
            key={column.field}
            align={column.numeric ? 'right' : 'left'}
            padding={column.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === column.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === column.field}
              direction={orderBy === column.field ? order : 'asc'}
              onClick={createSortHandler(column.field)}
            >
              {column.label}
              {orderBy === column.field ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ my: 2.75, flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Stack direction="row" alignItems="center" justifyContent="space-between" p={2}>
        <TextField 
            id="outlined-search" 
            label="Search" 
            type="search" 
            size="small" 
            value={props.filter}
            onChange={props.filterHandler}
            />

            
            </Stack>
      )}

      {numSelected > 0 &&
        <Tooltip title="Delete">
          <IconButton onClick={props.delete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      }
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  filter: PropTypes.string,
  filterHandler: PropTypes.func,
};

export default function TableEnhanced(props) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(props.defaultSort);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState((props.rowsPerPage > 0 ? props.rowsPerPage : 5));
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = React.useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState('');
  const [dialogShow, setDialogShow] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [dialogTitle, setDialogTitle] = useState('');
  const [confirmDialog, setConfirmDialog] = useState({});
  const { user } = useContext(UserContext);

  const getData = () => {
    if (props.url) {
        setIsLoading(true)
        fetch(url(props.url), {
            method: 'GET',
            credentials: 'include'
          })
        .then(res => res.json())
        .then(json => {
            setData(json)
            setFilteredData(json)
            setFilter('')
            setIsLoading(false)
        })
    } else {
        setData(props.data)
    }
  }

  const handleAdd = (e) => {
    setDialogTitle('New '+props.entityName)
    setCurrentRow({Enabled: true})
    setDialogShow(true)
  }

  const handleSave = (data, callback) => {
    if (props.url) {
        setIsLoading(true)
        fetch(url(props.url), {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(data)
        })
        .then(res => res.json())
        .then(json => {
            getData()
            if (callback) {
              callback(json)
            }
        })
    } else {
        props.save(data)
    }
}  

  const handleDelete = () => {
    setConfirmDialog(s => ({...s, ...{
        open: true,
        yesHandler: deleteSelected,
        text: `Delete ${selected.length} record(s)?`
    }}));
  }

  const deleteSelected = () => {
    if (props.url) {
        setIsLoading(true)
        fetch(url(props.url), {
            method: 'DELETE',
            credentials: 'include',
            body: JSON.stringify(selected)
          })
        .then(res => res.json())
        .then(json => {
            setSelected(json)
            getData()
        })
    } else {
        props.delete(selected, setSelected)
    }
  }

  const handleFilter = (value) => {
    setFilter(value)
    if (value && value !== " ") {
        var tmp = data.filter((element) => {
            var ret = false
            props.columns.forEach((obj) => {
                if (obj.filter) {
                    ret = ret || element[obj.field].toLowerCase().includes(value.toLowerCase())
                }
            });
            return ret
        })
        setFilteredData(tmp)
    } else {
        setFilteredData(data)
    }
  };

  useEffect(() => {
    getData()
  }, [user]);

  function getComparator(order, orderBy) {
    if (!orderBy)
        return null
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredData.map((n) => n.ID);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleCheckboxClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleClick = (event, row) => {
    setCurrentRow(row)
    setDialogTitle('Edit '+props.entityName)
    setDialogShow(true)
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
        <Loading show={isLoading} />
        <ConfirmDialog options={confirmDialog} setOptions={setConfirmDialog}/>
        <EditDialog title={dialogTitle} show={dialogShow} columns={props.columns} setShow={setDialogShow} save={handleSave} data={currentRow} />
        <Stack direction="row" alignItems="center" justifyContent="space-between" p={2}>
            {props.title && <Typography variant="h4">{props.title}</Typography>}
            <Button variant="contained" onClick={handleAdd} startIcon={<AddIcon />}>New</Button>
        </Stack>
      <Paper elevation={3} sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} filter={filter} delete={handleDelete} filterHandler={(e) => {handleFilter(e.target.value)}} />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={props.dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={filteredData.length}
              columns={props.columns}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 data.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(filteredData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.ID);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.ID}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox" >
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={(event) => handleCheckboxClick(event, row.ID)}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>

                      {props.columns.map((column) => {
                        if (column.type === 'switch') {
                            return (<TableCell key={column.field} padding="checkbox"><Checkbox
                                color="primary"
                                disabled
                                onClick={(event) => handleClick(event, row)} 
                                checked={row[column.field]}
                              /></TableCell>)
                        } else if (column.type === 'icon') {
                            return (<TableCell key={column.field} padding="checkbox">
                                {row[column.field] && <Tooltip disableFocusListener title={row[column.field]}><ErrorIcon sx={{ color: red[500] }} /></Tooltip>}
                                {!row[column.field] && <CheckIcon sx={{ color: green[500] }} />}
                                </TableCell>)
                        } else {
                            return (<TableCell 
                                key={column.field} 
                                onClick={(event) => handleClick(event, row)} 
                                align={column.align}>
                                    {row[column.field]}
                                    </TableCell>)
                        }
                      })}

                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (props.dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {
        <TablePagination
          labelRowsPerPage="Per page"
          rowsPerPageOptions={[5, 10, 20, 50]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        }
        
      </Paper>
      
    </Box>
  );
}

TableEnhanced.propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
    entityName: PropTypes.string,
    dense: PropTypes.bool,
    columns: PropTypes.array.isRequired,
    save: PropTypes.func,
    delete: PropTypes.func,
    rowsPerPage: PropTypes.number,
    defaultSort: PropTypes.string,
};