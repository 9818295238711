import React, { useEffect, useState } from 'react'
import { Grid, Box, Table, TableBody, TableCell, TableContainer, TableHead, Typography, TableRow, TextField, Toolbar } from '@mui/material'
import { Paper, Link } from '@mui/material'
import { Helmet } from 'react-helmet';

import Loading from '../../components/Loading.js';
import { url, stockUrl } from '../../functions.js'

export default function News() {
  const [isLoading, setIsLoading] = useState(false);
  const [dataOB, setDataOB] = useState([]);
  const [dataOS, setDataOS] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    fetch(url('/s/stocks/overboughtoversold'))
    .then(
      res => res.json())
    .then(json => {
      setDataOB(json.Overbought);
      setDataOS(json.Oversold);
      setIsLoading(false);
    })
  },[]);

  return (
    <Box>
      <Helmet>
        <title>Overbought/Oversold S&P500 - ontons.com</title>
        <meta name="description" content="overbought, oversold, low rsi, high rsi, S&P500 scanner, screener, watcher, investment idea" />
      </Helmet>
      <Loading show={isLoading} />

      <Typography component="div" variant="h4" sx={{ mt: 1, mb: 1}}>Overbought/Oversold S&P500 stocks</Typography> 

      <Typography component="div" variant="body1" sx={{ mt: 1, mb: 1}}>
      List of overbought/oversold S&P500 stocks based on daily Relative Strength Index (<Link  href="https://en.wikipedia.org/wiki/Relative_strength_index" target="_blank">RSI</Link>) indicator. Low RSI = oversold, High RSI = overbough. Updated daily.
      </Typography> 

      <Grid container spacing={1} alignItems="stretch">
          <Grid item xs={12} md={6} >
            <h4>Overbought</h4>

            <Paper elevation={3} sx={{ width: '100%', mb: 2, mt: 2 }}>
              <TableContainer>
                <Table size="small" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Symbol</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell align="right">Price</TableCell>
                      <TableCell align="right">RSI</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataOB?.map((row) => (
                      <TableRow hover key={row.Symbol} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>{row.Symbol}</TableCell>
                        <TableCell><Link  href={stockUrl(row.Symbol)} target="_blank">{row.Name}</Link></TableCell>
                        <TableCell align="right">{Math.abs(parseFloat(row.Price).toFixed(2))}$</TableCell>
                        <TableCell align="right">{Math.abs(parseFloat(row.RSI).toFixed(2))}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>

          </Grid>

          <Grid item xs={12} md={6}>
            <h4>Oversold</h4>
            <Paper elevation={3} sx={{ width: '100%', mb: 2, mt: 2 }}>
              <TableContainer>
                <Table size="small" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Symbol</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell align="right">Price</TableCell>
                      <TableCell align="right">RSI</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataOS?.map((row) => (
                      <TableRow hover key={row.Symbol} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>{row.Symbol}</TableCell>
                        <TableCell><Link  href={stockUrl(row.Symbol)} target="_blank">{row.Name}</Link></TableCell>
                        <TableCell align="right">{Math.abs(parseFloat(row.Price).toFixed(2))}$</TableCell>
                        <TableCell align="right">{Math.abs(parseFloat(row.RSI).toFixed(2))}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
      
        
      </Grid>

      <Paper elevation={3} sx={{ width: '100%', mb: 2, mt: 2 }}>
      
    </Paper>
    </Box>
  )
}