import React, { useEffect, useRef, useContext, useState } from 'react'
import { Box } from '@mui/material'

import UserContext from "../contexts/UserContext";
import { url } from "../functions.js"
import Loading from './Loading';

export default function GoogleSignIn() {
    const { setUser } = useContext(UserContext);
    const divRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (divRef.current) {
          setUser(s => ({...s, ...{loading: true}}))
          fetch(url('/s/user'), {
            method: 'GET',
            credentials: 'include'
          })
            .then(res => res.json())
            .then(json => {
                setUser(json);
                if (!json.LoggedIn) {
                  window.google.accounts.id.initialize({
                    client_id: process.env.REACT_APP_GCID,
                    callback: (res, error) => {
                        setIsLoading(true);
                        fetch(url('/s/signin'), {
                            method: 'POST',
                            body: JSON.stringify(res),
                            credentials: 'include'
                        })
                            .then(res => res.json())
                            .then(json => {
                              json.loading = false;
                              setUser(json);
                              setIsLoading(false);
                            });
                    },
                  });
                  window.google.accounts.id.renderButton(divRef.current, {
                    theme: 'outline',
                    size: 'medium',
                    type: 'standard',
                    text: 'signin',
                  });
                  divRef.current.style.display = "block"
                }
            });
        }
      }, [divRef.current]);

    return (
        <div>
          <Loading show={isLoading} />
          <Box id="GSigninBtn" sx={{ display: 'none' }} ref={divRef} />
        </div>
    )
}
