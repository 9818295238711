import React from 'react'
import { Backdrop, CircularProgress } from '@mui/material'
import PropTypes from 'prop-types';

const Loading = props => {
  return (
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.show}
      >
        <CircularProgress color="inherit" />
    </Backdrop>
  )
}

Loading.propTypes = {
    show: PropTypes.bool.isRequired
};

export default Loading