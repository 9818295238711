import React, { Component } from 'react';
import { Box } from '@mui/system';
import { Grid, Stack, Typography } from '@mui/material';

import PriceChange from './PriceChange';
import { url } from "./../functions.js";

class RatesLine extends Component {
    constructor(props) {
        super(props);
        this.state = {rates: []};
    }

    fetchData() {
        fetch(url('/s/rates'))
        .then(res => res.json())
        .then(json => {
            this.setState({ rates: json });
        });
        
    }

    componentDidMount() {
        this.fetchData();
        setInterval(() => {
            this.fetchData();
        }, 3000);
    }

    render() {
        return (
            <Grid container mt={1} mb={1} alignItems="stretch">
                {this.state.rates.map((rate) => (
                <Grid item my={1} xs={'auto'} sm={4} md={4} lg={2} key={rate.symbol}>
                    <Stack direction="row" alignItems="center">
                        <Box
                            component="img"
                            mx={0.5}
                            sx={{
                            height: 18,
                            width: 18
                            }}
                            src={"/img/c/" + rate.SymbolSingle.toLowerCase() + ".png"}
                        />
                        <Typography mt={0.3} variant="body2">
                            {rate.SymbolSingle} {"$" + parseFloat(rate.lastPrice).toFixed(2)}
                        </Typography>
                        <Box mt={0.3}>
                        <PriceChange change={parseFloat(rate.priceChangePercent).toFixed(2)}/>
                        </Box>
                        
                    </Stack>
                </Grid>
                ))}
            </Grid>
        );
    }
}

export default RatesLine;