import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Switch, FormControlLabel } from '@mui/material'

const EditDialog = props => {
  const [data, setData] = useState({});
  const [messageOpen, setMessageOpen] = useState(false);
  const handleClose = () => {
    props.setShow(false);
  };
  const handleSave = () => {
    props.save(data, saveCallback);
  };
  const saveCallback = (data) => {
    if (data.ID) {
      props.setShow(false);
    } else {
      setData(data)
      setMessageOpen(true)
    }
  }
  
  const handleFieldChange = (column, e) => {
    /*let tmp = Object.assign({}, data)
    if (column.type == "switch") {
        tmp[column.field] = e.target.checked
    } else {
        tmp[column.field] = e.target.value
    }
    setData(tmp)*/
    setData(prevState => {
        let data = Object.assign({}, prevState)
        if (column.type === "switch") {
            data[column.field] = e.target.checked
        } else {
            data[column.field] = e.target.value
        }                            
        return data                                
    })
  };

  useEffect(() => {
    setData(props.data)
    }, [props.data]);

  return (
    <div>
      <Dialog
        open={messageOpen}
        onClose={() => {setMessageOpen(false)}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Error
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {data.Error}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {setMessageOpen(false)}} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={props.show} onClose={handleClose} fullWidth={true} maxWidth="md">
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
        {props.columns.map((column) => {
            if (!column.editDialogHide)
            {
                if (column.type === 'switch') {
                    return (<FormControlLabel 
                        key={column.field}
                        disabled={column.disabled} 
                        control={<Switch />} 
                        label={column.label}
                        type={column.type}
                        checked={data[column.field] || false}
                        onChange={(e) => handleFieldChange(column, e)}
                        />)  
                } else {
                    return (<TextField
                        autoFocus
                        margin="dense"
                        key={column.field}
                        label={column.label}
                        type={column.type}
                        fullWidth
                        variant="standard"
                        value={data[column.field] || ''}
                        onChange={(e) => handleFieldChange(column, e)}
                        disabled={column.disabled}
                        onKeyPress= {(e) => {if (e.key === 'Enter') {handleSave()}}}
                        />)  
                }
            }    
            return null 
        }
        )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" type="submit" onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

EditDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    columns: PropTypes.array,
    save: PropTypes.func,
    data: PropTypes.object,
}

export default EditDialog