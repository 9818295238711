import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material';
import { Typography } from '@mui/material';
import { Helmet } from 'react-helmet';


import Loading from './../components/Loading';
import SimpleList from '../components/SimpleList.js';
import { url, dateStr, decimals } from './../functions.js'

export default function Monitor() {
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
        fetch(url('/s/monitor'))
        .then(res => res.json())
        .then(json => {
            setData(json);
            setIsLoading(false);
        });
      };
      fetchData()
    const t = setInterval(fetchData, 15000);

    return () => clearInterval(t); // clear
    
    
    
  }, []);
  
 
  return (
    <div>
      <Helmet>
        <title>Monitor - ontons.com</title>
        <meta name="description" content="Cryptocurrency monitor, screener, gainers, loosers, trade signals" />
      </Helmet>

      <Loading show={isLoading} />

        <Grid container spacing={1} alignItems="stretch">
            <Grid item xs={12} md={12} >
            <Typography variant="h4" component="div">Crypto monitor</Typography>
            <Typography component="div">Monitors Binance crypto USDT prices. Updates every minute (last updated: {dateStr(data.LastUpdate)}).</Typography>
            <Typography component="div">More indicators coming soon!</Typography>
            </Grid>

            <Grid item xs={12} md={4} >
                <SimpleList 
                    title="Gainers (5 minute)" 
                    id="Symbol"
                    rows={data?.Gainers5m}
                    cols={[
                        {field:"Symbol", type: "exchUrl", size: 6}, 
                        {field:"Percent", type: "plusPercent", size: 6}]}
                    />
            </Grid>

            <Grid item xs={12} md={4} >
                <SimpleList 
                    title="Gainers (15 minute)" 
                    id="Symbol"
                    rows={data?.Gainers5m}
                    cols={[
                        {field:"Symbol", type: "exchUrl", size: 6}, 
                        {field:"Percent", type: "plusPercent", size: 6}]}
                    />
            </Grid>

            <Grid item xs={12} md={4} >
                <SimpleList 
                    title="Overbought (15 minute)" 
                    id="Symbol"
                    rows={data?.Overbought15m}
                    cols={[
                        {field: "Symbol", type: "exchUrl", size: 6}, 
                        {field: "RSI", textAlign: 'right', color: 'green', func: (value) => "RSI="+decimals(value, 2), size: 6}]}
                    />
            </Grid>



            <Grid item xs={12} md={4} >
                <SimpleList 
                    title="Losers (5 minute)" 
                    id="Symbol"
                    rows={data?.Losers5m}
                    cols={[
                        {field:"Symbol", type: "exchUrl", size: 6}, 
                        {field:"Percent", type: "minusPercent", size: 6}]}
                    />
            </Grid>

            <Grid item xs={12} md={4} >
                <SimpleList 
                    title="Losers (15 minute)" 
                    id="Symbol"
                    rows={data?.Losers5m}
                    cols={[
                        {field:"Symbol", type: "exchUrl", size: 6}, 
                        {field:"Percent", type: "minusPercent", size: 6}]}
                    />
            </Grid>

            <Grid item xs={12} md={4} >
                <SimpleList 
                    title="Oversold (15 minute)" 
                    id="Symbol"
                    rows={data?.Oversold15m}
                    cols={[
                        {field: "Symbol", type: "exchUrl", size: 6}, 
                        {field: "RSI", textAlign: 'right', color: 'red', func: (value) => "RSI="+decimals(value, 2), size: 6}]}
                    />
            </Grid>

        </Grid>
    </div>
  )
}