import * as React from 'react';
import { Box, Grid, Typography, Container, Link } from '@mui/material';

function Copyright() {
  return (
    <Typography 
      variant="body2" 
      color="text.secondary"
      sx={{
        mt: 3
      }}>
      {'Copyright © '}
      <Link color="inherit" href="https://ontons.com/">
        ontons.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function StickyFooter() {
  return (
   
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: 'auto',
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <strong>Contact us:</strong><br />
              <Link href="mailto:info@ontons.com">info@ontons.com</Link><br />
            </Grid>
            <Grid item xs={12} md={4}>
              <strong>Support us:</strong><br />
              <br />
              
            </Grid>
            <Grid item xs={12} md={4}>
              <strong>Legal:</strong><br />
              <Link href="privacy.html">Privacy Policy</Link><br />
              <Link href="toc.html">Terms and Conditions</Link><br />
            </Grid>
          </Grid>

          <Typography variant="body1">
            
          </Typography>
          <Copyright />
        </Container>
      </Box>

  );
}