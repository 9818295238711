import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

class Change extends Component {
    render() {
        const positive = this.props.change >= 0 ? true : false;
        return (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                color: positive ? "green" : "red"
            }}>
                {positive ? (<ArrowDropUpIcon />) : (<ArrowDropDownIcon />)} 
                <Typography variant="body2">
                    {this.props.change + "%"}
                </Typography>
            </div>  
        );
    }
}

Change.propTypes = {
    Change: PropTypes.number
};

export default Change;