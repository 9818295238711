
import { ThemeOptions } from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
  /*
  402E1F 73553C 8B7863 F27B35 F2E8C9
  */
  palette: {
    type: 'light',
    primary: {
      main: '#402E1F',
      light: '#F27B35',
      contrastText: '#F2E8C9',
    },
    secondary: {
      main: '#73553C',
    },
    background: {
      default: '#F5F5F5',
    },
  },
};