export const url = (extra) => {
   return process.env.REACT_APP_BACK+(extra ? extra : '')
}

export const countDecimals = (pair, value) => {
   let text = value.toString()
   // verify if number 0.000005 is represented as "5e-6"
   if (text.indexOf('e-') > -1) {
     let [base, trail] = text.split('e-');
     let deg = parseInt(trail, 10);
     deg = deg + Math.max(base.length - 2, 0);
 
     return deg;
   }
   // count decimals for number in representation like "0.123456"
   if (Math.floor(value) !== value) {
     return value.toString().split(".")[1].length || 0;
   }
   return 0;
 }
 export const decimals = (num, decimals) => {
  return (Math.round(num * 100) / 100).toFixed(decimals);
 }

 

export const dateStr = (date) => {
  let dateLocal = new Date(date);
  //let newDate = new Date(
  //  dateLocal.getTime() - dateLocal.getTimezoneOffset() * 60 * 1000
  //);
  return dateLocal.toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
};

export const stockUrl = (symbol) => {
   return "https://finance.yahoo.com/quote/"+symbol
}

export const exchUrl = (exch, symbol1, symbol2) => {
  if (exch === "Binance") {
    return 'https://www.binance.com/en/trade/'+symbol1+'_'+symbol2+'?type=cross';
  }
  if (exch === "Ftx") {
    return 'https://ftx.com/trade/'+symbol1+'/'+symbol2;
  }
  if (exch === "Okx") {
    return 'https://www.okx.com/trade-spot/'+symbol1+'-'+symbol2;
  }
  if (exch === "Bybit") {
    return 'https://www.bybit.com/en-US/trade/spot/'+symbol1+'/'+symbol2;
  }
  if (exch === "KuCoin") {
    return 'https://www.kucoin.com/trade/'+symbol1+'-'+symbol2;
  }
}